.dndflow {
  flex-direction: column;
  display: flex;
  height: 100%;
}

.dndflow aside {
  border-right: 1px solid #eee;
  padding: 15px 10px;
  font-size: 12px;
  background: #fcfcfc;
}

.dndflow aside .description {
  margin-bottom: 10px;
}
/* .react-flow__arrowhead {
  z-index: 9999999 !important;
} */
.leftLine{
  height: 100%;
  position: absolute;
  width: 60px;
  top: 0px;
  background-color: #222222;
  left: 0px;
}

.dndflow .dndnode {
  height: 20px;
  padding: 4px;
  border: 1px solid #1a192b;
  border-radius: 2px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
}

.input{
  box-sizing: border-box;
    width: 100%;
    padding: 8px;
    text-overflow: ellipsis;
    outline: none;
    height: 35px;
    background: #fff;
    border: 1px solid #d4d4d4;
    opacity: 1;
    text-align: left;
    font-family: realist, sans-serif;
    letter-spacing: 0;
    color: #191919;
    margin-bottom: 16px;
    margin-top: 3px;
}

.outDiv{
  width: 91%;
    height: 47px;
    margin-top: 20px;
    /* margin: 10 10; */
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f5f5f5;
    border-bottom: 1px solid #d4d4d4;
    border-top: 1px solid #d4d4d4;
    box-sizing: content-box;

}

.iconP{
  height: 23px;
  width: 23px;
  border-radius: 50%;
  background: #0057ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  margin-right: 8px;
  outline: none;
}

.comp{
  text-align: left;
  font-family: realist, sans-serif;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0;
  color: #3a3a3a;
  margin-bottom: 40px;
}

.lable{
  text-align: left;
  font-family: realist, sans-serif;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0;
  color: #3a3a3a;
}

.outP{
  text-align: left;
  font-family: realist, sans-serif;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0;
  color: #3a3a3a;
}
.custom-pre{
  height: 220px; /* Set the height to your desired value */
  overflow: auto; /* Add a scrollbar if content overflows */
  background-color: #f0f0f0; /* Optional: Add a background color */
  border: 1px solid #ccc; /* Optional: Add a border */
  padding: 10px; /* Optional: Add padding */
}

.dndflow .dndnode.input {
  border-color: #0041d0;
}

.dndflow .dndnode.output {
  border-color: #ff0072;
}

.dndflow .reactflow-wrapper {
  flex-grow: 1;
  height: 100%;
}

@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
  }

  .dndflow aside {
    width: 20%;
    max-width: 250px;
  }
}
